<template>

    <div id="assetTrackingDashboard" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page" style=" margin-top: -55px; ">

                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="assettrackingdashboard"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <app-accesserrorportlet
                        ref="accessErrorPortlet"
                        v-show="!accessGranted"
                        activeItem="assetTrackingDashboard"
                        :siteId="siteId">
                    </app-accesserrorportlet>

                    <div v-if="accessGranted" class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <app-locationmap ref="locationmap" mode="assetsTracking"></app-locationmap>
                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationMap from "../map/locationmap.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import i18n from "./../../i18n";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteBuildingsTable: null,
            isMounted: false,
            scheduleFetchAssets: null,
            isLocatedGatewayOrBeaconDown: false,
            generateVisualizationLink: null
        };
    },
    created: function() {
        console.log("Component(assettrackingdashboard)::created() - called");
        if (this.$route.query && this.$route.query.highlight) {
            // Store array of mac address to highlight in store
            this.setArrayOfMacAddressToHighlight(
                this.$route.query.highlight.split(",")
            );
        }
        // get wall types
        this.getWallTypes();
        // get BLE gateways
        this.getSiteBLEGateways(this.siteId);
        // get autocalibrationTags, if any
        this.getSiteAutoCalibrationTags(this.siteId);
        // Get geofences
        this.getSiteGeofences(this.siteId);
        // Get walkways
        this.getSiteWalkways(this.siteId);
        // Get walls
        this.getSiteWalls(this.siteId);
        // Get Site Gateways Health
        this.getSiteGatewaysHealth(this.siteId);
    },
    mounted: function() {
        console.log("Component(assettrackingdashboard)::mounted() - Init metronic layout");
        this.isMounted = true;
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(assettrackingdashboard)::destroyed() - called");

        // Close lateral bar if opened
        if ($("#assetDetailBar").hasClass("kt-demo-panel--on")) {
            $("#assetDetailBar").toggleClass("kt-demo-panel--on");
        }

        this.siteBuildingsTable = null;
        this.generateVisualizationLink = null;

        this.stopFetchAssetsInterval();
        this.scheduleFetchAssets = null;
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        this.resetWallsState();
        this.resetGatewaysState();
        this.resetAutocalibrationTagsState();
        this.resetGeofencesState();
        this.resetWalkwaysState();
        this.resetAssetsState();
        this.resetSiteHealthState();

        this.setCurrentOpenedAssetIdInDashboard("");
        this.resetAssetsState();
        this.resetArrayOfMacAddressToHighlight();
        this.resetMapMode();
        this.resetSearchedItems();
        this.stopFetchAssetsInterval();
        // Dispose popover
        $(".popover").popover('dispose');
        // Delete in locale storage the status of popover
        localStorage.removeItem(this.getSelectedFloorId + '_generateShowAssetLinkPopoverOpen');
        next();
    },
    watch: {

        siteGatewaysHealth() {
            // Get Site Tags Health
            // this.getSiteTagsHealth(this.siteId);

            // Treat Gateways
            this.gatewaysDown = [];
            if (this.siteGatewaysHealth && this.siteGatewaysHealth.length > 0) {
                for (let gateway of this.siteGatewaysHealth) {
                    if (gateway.building && gateway.floor) {
                        if (!gateway.lastEmission || gateway.lastEmission === -1) {
                            this.gatewaysDown.push(gateway);
                        }
                    }
                }
            } else {
                this.gatewaysDown = [];
            }

            // Treat Beacons
            // this.beaconsOutCoverage = [];
            // if (this.siteTagsHealth && this.siteTagsHealth.length > 0) {
            //     for (let tag of this.siteTagsHealth) {
            //         if (tag.building && tag.floor) {
            //             if (!tag.coverage || tag.coverage.length === 0) {
            //                 this.beaconsOutCoverage.push(tag);
            //             }
            //         }
            //     }
            // } else {
            //     this.beaconsOutCoverage = [];
            // }

            // At the end, check if there is any issue either with the beacons or with the gateways.
            // If any, display a warning message
            if (this.gatewaysDown.length > 0) {
                let notif = `
                    <div id="apOrBeaconWarningModal" class="leaflet-right apOrBeaconDown">
                        <div style="text-align: left;font-size: 16px;" class="leaflet-drag-target">
                            <i class="la la-warning" style="font-size: 17px;color: #e61b45;"></i>
                            <span style="color: #e61b45;">`+i18n.t("site_assetSearchDegradedMode")+`</span>
                            <i id="apOrBeaconWarningModalCloseButton" class="la la-close" style="font-size: 15px;color: #1e1e2d;float: right;font-weight: 600;cursor:pointer"></i>
                        </div>
                        <div class="mt-1" style="color: #28272b;">`+i18n.t("site_assetSearchDegradedModeDesc")+`</div>
                    </div>
                `;
                $("#map").prepend(notif);

                $("#apOrBeaconWarningModalCloseButton").off().on("click", function() {
                    $("#apOrBeaconWarningModal").remove();
                    leafletMap.dragging.enable();
                });
                let downModal = document.getElementById("apOrBeaconWarningModal");
                // Disable dragging on map when user enter and leave the slider area
                L.DomEvent.on(downModal, "mousedown mouseup click touchstart", L.DomEvent.stopPropagation);
                L.DomEvent.on(downModal, "mouseenter", () => {
                    leafletMap.dragging.disable();
                });
                L.DomEvent.on(downModal, "mouseleave", () => {
                    leafletMap.dragging.enable();
                });

            }

        },

        searchedItems() {
            if (this.searchedItems) {
                // Delete in locale storage the status of popover
                localStorage.removeItem(this.getSelectedFloorId + '_generateShowAssetLinkPopoverOpen');

                const searchedItems = this.searchedItems;
                const searchItemByAssetName = searchedItems.find(item => item.type === "ASSET_NAME");
                const searchItemByTag = searchedItems.find(item => item.type === "TAG_SERIAL");
                const searchItemByCategory = searchedItems.find(item => item.type === "CATEGORY");
                if (searchItemByAssetName || searchItemByTag || searchItemByCategory) {
                    let filters = this.getFormatedFilters();

                    let payload = {
                        siteId: this.siteId,
                        filters: filters,
                    };

                    if (searchItemByAssetName) {
                        payload.filters.assets.push(searchItemByAssetName.value);
                    }

                    if (searchItemByTag) {
                        payload.filters.tagSerialNumbers.push(searchItemByTag.value);
                        payload.filters.assets.push(searchItemByTag.asset);
                    }
                    if (searchItemByCategory) {
                        payload.filters.categories.push(searchItemByCategory.value);
                    }

                    this.searchAssetsInSite(payload);
                    this.startFetchAssetsInterval();
                }

                else if (this.isMapRefreshingEnabled) {
                    this.fetchAssets();
                    this.startFetchAssetsInterval();
                } else {
                    this.fetchAssets();
                }
            }
        },

        getSelectedFloorId() {
            // To be able to track assets, we must have values for buildingId and floorId
            if (
                this.getSelectedBuildingId !== "" &&
                this.getSelectedFloorId !== ""
            ) {
                // Delete in locale storage the status of popover
                localStorage.removeItem(this.getSelectedFloorId + '_generateShowAssetLinkPopoverOpen');
                // Floor Change clear interval of previous store
                this.stopFetchAssetsInterval();
                if (this.isMapRefreshingEnabled) {
                    // refresh mode so we start the resfreshing
                    this.startFetchAssetsInterval();
                }
                // A floorId has changed, we track assets with this new buildingId
                this.fetchAssets();
            }
            // Dispose popover
            $(".popover").popover('dispose');
        },

        isMapRefreshingEnabled() {
            // When buildings and their floors have been loaded, call api to fetch assets
            if (this.isMapRefreshingEnabled) {
                this.fetchAssets();
                this.startFetchAssetsInterval();
            } else {
                this.stopFetchAssetsInterval();
            }
        },

        mapRefreshingIntervalInSeconds() {
            // When buildings and their floors have been loaded, call api to fetch assets
            if (this.isMapRefreshingEnabled) {
                this.startFetchAssetsInterval();
            }
        },

        // Called each time a change is made on filters, refresh assets on the dashboard is filtering option is activated
        activeFilters() {
            if (this.isMapFilteringEnabled) {
                this.fetchAssets();
                if (this.isMapRefreshingEnabled) {
                    this.startFetchAssetsInterval();
                }
            }
        },

        // Called each time user updates status of the switch filtering button
        isMapFilteringEnabled() {
            this.fetchAssets();
            if (this.isMapRefreshingEnabled) {
                this.startFetchAssetsInterval();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "searchedItems",
            "isMapFilteringEnabled",
            "activeFilters",
            "buildingsAndTheirFloorsArray",
            "getSelectedBuildingId",
            "getSelectedFloorId",
            "isMapRefreshingEnabled",
            "mapRefreshingIntervalInSeconds",
            "siteAssetCategories",
            "siteGatewaysHealth",
            // "siteTagsHealth",
            "isSearchRequestInProgress"
        ]),

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "resetAssetsState",
            "resetWallsState",
            "resetWalkwaysState",
            "resetAutocalibrationTagsState",
            "resetGatewaysState",
            "getWallTypes",
            "resetGeofencesState",
            "setArrayOfMacAddressToHighlight",
            "resetArrayOfMacAddressToHighlight",
            "resetSearchedItems",
            "resetMapMode",
            "resetAssetsState",
            "getSiteAssetCategories",
            "getSiteGeofences",
            "getSiteWalkways",
            "getSiteWalls",
            "setCurrentMapTilesLayerOpacity",
            "setCurrentMapTilesLayerName",
            "searchAssetsInFloor",
            "searchAssetsInSite",
            "setCurrentOpenedAssetIdInDashboard",
            "getSiteBLEGateways",
            "getSiteAutoCalibrationTags",
            "resetSiteHealthState",
            "getSiteGatewaysHealth",
            // "getSiteTagsHealth"
        ]),

        startFetchAssetsInterval() {
            this.stopFetchAssetsInterval();
            this.scheduleFetchAssets = setInterval(
                this.fetchAssets,
                this.mapRefreshingIntervalInSeconds * 1000
            );
        },

        stopFetchAssetsInterval() {
            if (this.scheduleFetchAssets) {
                clearInterval(this.scheduleFetchAssets);
            }
        },

        fetchAssets() {
            if (!this.isSearchRequestInProgress) {
                if (this.getSelectedBuildingId && this.getSelectedFloorId) {
                    // This is the map filters
                    let filters = this.getFormatedFilters();

                    let payload = {
                        siteId: this.siteId,
                        buildingId: this.getSelectedBuildingId,
                        floorId: this.getSelectedFloorId,
                        filters: filters
                    };

                    // Retrieve search item
                    const searchedItems = this.searchedItems;
                    for (let i = 0; i < searchedItems.length; i++) {
                        let currentItem = searchedItems[i];
                        if (currentItem.type === "CATEGORY") {
                            payload.filters.categories.push(currentItem.value);
                        } else if (currentItem.type === "ASSET_NAME") {
                            payload.filters.assets.push(currentItem.value);
                        } else if (currentItem.type === "TAG_SERIAL") {
                            payload.filters.tagSerialNumbers.push(currentItem.value);
                            payload.filters.assets.push(currentItem.asset || null);
                        }
                    }

                    this.searchAssetsInFloor(payload);
                }
            } else {
                console.log("Resquest already sent");
            }
        },

        // Function used to return a JSON object containing list of active Filters
        getFormatedFilters() {
            let filters = {
                assets: [],
                categories: [],
                units: [],
                tagTypes: [],
                tagMacAddresses: [],
                tagSerialNumbers: [],
                availability: [],
                geofences: []
            };

            // If filtering is disabled, no filters applied.
            if (this.isMapFilteringEnabled == false) {
                return filters;
            }

            for (let i = 0; i < this.activeFilters.length; i++) {
                switch (this.activeFilters[i].keyLabel) {
                    case "MAP_ASSET_NAME":
                        filters.assets.push(this.activeFilters[i].value);
                        break;
                    case "MAP_TAG_SERIAL_NUMBER":
                        filters.tagSerialNumbers.push(
                            this.activeFilters[i].value
                        );
                        break;
                    case "MAP_CATEGORY":
                        filters.categories.push(this.activeFilters[i].keyValue);
                        break;
                    case "MAP_GEOFENCE":
                        filters.geofences.push(this.activeFilters[i].keyValue);
                        break;
                    case "MAP_AVAILABILITY":
                        filters.availability.push(this.activeFilters[i].keyValue);
                        break;
                    case "MAP_UNIT":
                        filters.units.push(this.activeFilters[i].keyValue);
                        break;
                    case "MAP_TAG_TYPE":
                        filters.tagTypes.push(this.activeFilters[i].keyValue);
                        break;
                }
            }

            return filters;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-locationmap": LocationMap,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
